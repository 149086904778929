import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"

import SEO from "../components/seo"
import Hero from "../components/hero"
import Features from "../components/features"

import Quote from "../components/quote"
import Cards from "../components/cards"
import Explainer from "../components/explainer"
import JobList from "../components/joblist"
import CTA from "../components/cta"
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "careers.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    beachImage: file(relativePath: { eq: "beach.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cultureImage: file(relativePath: { eq: "culture.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    learningImage: file(relativePath: { eq: "learning.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Home" />
    <Hero
      headline={(<span>Help great brands be <u>successful</u> with Shopify.</span>)}
      dek="Join our team of incredible Shopify technical experts. We’re looking for top-notch talent. Is that you?"
      bullets={[
        {icon: "ti-heart", text: "Advance your career and learn new skills"},
        {icon: "ti-comments-smiley", text: "Work with happy clients, and great people"},
        {icon: "ti-package", text: "Get equitable pay and great benefits"}
      ]}
      hero={
        <Img fluid={data.heroImage.childImageSharp.fluid} />
      }
      cta="Find a job"
      ctaLink="https://apply.workable.com/managedcommerce/"/>

    <Quote/>
    <Cards

      headline={<span>Find your <u>purpose</u></span>}
      dek={(
        <>
          <p className="title-subtitle title-subtitle-dek mx-auto">
            Looking for a bold mission? A meaningful purpose? A great team?
            A kind and caring company? We have it all.
          </p>
          <p className="title-subtitle mx-auto">
            You'll join a team that starts every day with the goal of helping
            our customers be successful on their ecommerce adventure. A team
            that loves helping growing brands stand toe-to-toe with retail
            giants.
          </p>
        </>
      )}


      cards={[
        {
          icon: "ti-heart",
          class: "large",
          heading: "Invested in you",
          text: "Join a team that invests in you, with a belief in the power of continued growth and lifelong learning."
        },
        {
          icon: "ti-panel",
          class: "large",
          heading: "Flexible work",
          text: "Choose your working style. 1099 or W2? Mornings or Evenings? Beach or Mountain? Mac or PC? You decide."
        },
        {
          icon: "ti-world",
          class: "large",
          heading: "Meaningful impact",
          text: "Work with customers you love, and make a meaningful impact to their businesses. Say goodbye to boring."
        },
        {
          icon: "ti-face-smile",
          class: "large",
          heading: "A great culture",
          text: "Find your tribe, a group of people that lift you up, every day, from our staff to our clients."
        }
      ]}
    />
    <Cards
      headline={<span>A <u>values</u> driven team</span>}
      dek="We believe that creating a great culture is the best gravity field for great customers and great people."
      cards={[
          {icon: "ti-thumb-up", heading: "Make it easy", text:"We make things easy, even when times are hard."},
          {icon: "ti-medall", heading: "Finish", text: "We take every challenge all the way to the finish line."},
          {icon: "ti-world", heading: "Improve the world", text: "We leave everything a little bit better than we found it."},
          {icon: "ti-face-smile", heading: "Bring the love", text: "We find the passion in our work, our customers, and our teammates."},
          {icon: "ti-crown", heading: "Lift each other up", text: "We’re proud of our constructive feedback process and judgement-free environment."},
          {icon: "ti-check-box", heading: "Be predictable", text: "We call our shots, then make them a reality."},
          {icon: "ti-star", heading: "Be pragmatic", text: "Absent information, we use our intuition and breadth of knowledge to find the right path."},
          {icon: "ti-shield", heading: "Be humble", text: "We always remember who the heroes are: our customers."}
      ]}
    />
    <CTA
      heading="Find an open position..."
      cta="View jobs"
      ctaLink="https://apply.workable.com/managedcommerce/"
    />
  </Layout>
)

export default IndexPage
