import React from "react"
import PropTypes from "prop-types"
import Image from "./image"

class JobList extends React.Component {

  constructor(props){
    super(props)
    this.domRef = React.createRef();
  }

  componentDidMount(){
    const s1 = document.createElement('script');
    s1.type = 'text/javascript';
    s1.async = true;
    s1.src = 'https://www.workable.com/assets/embed.js';

    const s2 = document.createElement('script');
    s2.type = 'text/javascript';
    s2.async = true;
    s2.innerHTML = "whr_embed(455954, {detail: 'titles', base: 'jobs', zoom: 'country', grouping: 'none'});";

    this.instance.appendChild(s1);
    //this.instance.appendChild(s2);
  }

  render(){
    return (
      <section ref={el => (this.instance = el)} class="section" id="jobs">
        <div class="container">
          <div class="row">
              <div class="col">
                <div id="whr_embed_hook"></div>
              </div>
          </div>
        </div>
      </section>
    )
  }
}

export default JobList
