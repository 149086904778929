import React from "react"

const Quote = () => (
    <section class="section bg-client" id="testi">
        <div class="container">
            <div class="row justify-content-center mt-5 mb-5">
                <div class="col-lg mb-5">
                    <div class="text-center w-75 mx-auto">
                        <div class="testi-icon text-white">
                            <i class="ti-quote-left"></i>
                        </div>
                        <div class="mt-3">
                            <h2 class="text-white text-center">
                              We help growing stores stand toe-to-toe with retail giants.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Quote
